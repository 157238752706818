var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import ReactDOM from 'react-dom';
import cookies from "btm.cookies/cookies";
import { compose } from 'dss-composable-components';
import { BroadcastServiceActions } from '../../../scripts/services/actions';
import uuid from "./tracking-minified/uuid";
import './tracking';
var crawlerUserAgents = ['adidxbot', 'adsbot-google', 'baiduspider', 'bingbot', 'facebookexternalhit/1.1', 'googlebot', 'ia_archiver', 'optimizelyedit', 'twitterbot', 'silktide', 'slurp'];
function isCrawlerBot(userAgent) {
  if (!userAgent) {
    return false;
  }
  return crawlerUserAgents.some(function (bot) {
    return userAgent.toLowerCase().includes(bot);
  });
}
function getSession(sessionId) {
  var mlpSession = cookies.get('mlp_session');
  if (!mlpSession) {
    cookies.set({
      name: 'mlp_session',
      value: sessionId,
      domain: '.espn.com'
    });
    return sessionId;
  }
  return mlpSession;
}
function updateDssId(userTrackingInfo, sessionId) {
  var dssId = userTrackingInfo === null || userTrackingInfo === void 0 ? void 0 : userTrackingInfo.dssId.split(',');
  if (dssId) {
    dssId[1] = sessionId;
    userTrackingInfo.dssId = dssId.join(',');
  }
  return userTrackingInfo;
}
var rootDiv = document.querySelector('#root');
export function getBroadcastService(maxAttempts) {
  if (maxAttempts === void 0) {
    maxAttempts = 1;
  }
  return __awaiter(this, void 0, void 0, function () {
    var attempt;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          attempt = 0;
          _a.label = 1;
        case 1:
          if (!(!window.hasOwnProperty('broadcastService') && attempt < maxAttempts)) return [3, 3];
          return [4, new Promise(function (resolve) {
            return setTimeout(resolve, 100);
          })];
        case 2:
          _a.sent();
          attempt++;
          return [3, 1];
        case 3:
          return [2, Promise.resolve(window.broadcastService)];
      }
    });
  });
}
(function init() {
  return __awaiter(this, void 0, void 0, function () {
    var broadcastService, params, runningExperiments;
    var _this = this;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4, getBroadcastService(500)];
        case 1:
          broadcastService = _a.sent();
          params = new URLSearchParams(window.location.search);
          runningExperiments = (params.get('experiments') || '').split(',').filter(Boolean);
          broadcastService.once(BroadcastServiceActions.User.userSet, function (_a) {
            var userTrackingInfo = _a.userTrackingInfo;
            var sessionId = uuid();
            var session = getSession(sessionId);
            userTrackingInfo = updateDssId(userTrackingInfo, session);
            window.btmTracking.userTrackingInfo = userTrackingInfo;
            window.btmTracking.trackPageView();
          });
          if (isCrawlerBot(window.navigator.userAgent)) {
            ReactDOM.render(compose(window.rehydrateJSON, runningExperiments), rootDiv, function () {
              return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  window.rehydrateJSON = {};
                  return [2];
                });
              });
            });
          } else {
            broadcastService.on(BroadcastServiceActions.Application.ready, function (applicationState) {
              var sdkExperiments = applicationState.sdkService.experiments;
              if (runningExperiments.length === 0) {
                for (var key in sdkExperiments) {
                  var experimentIdentifier = key + "-" + sdkExperiments[key].variantId;
                  runningExperiments.push(experimentIdentifier);
                }
              }
              ReactDOM.render(compose(window.rehydrateJSON, runningExperiments), rootDiv, function () {
                return __awaiter(_this, void 0, void 0, function () {
                  return __generator(this, function (_a) {
                    window.rehydrateJSON = {};
                    setTimeout(function () {
                      applicationState.oneIdService.setEntitlementLevel(applicationState.userService.accountDetails.entitlementLevel);
                    }, 200);
                    return [2];
                  });
                });
              });
            });
          }
          return [2];
      }
    });
  });
})();