var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import omniturePlugins from '../tracking-minified/omniturePlugins';
import getGlobalContextVars from './globalContextVars';
import { deepGet } from 'bam-deep-get';
var dssDataLayer = {
  pageName: 'Welcome - ESPN MLP',
  webAppName: 'espnplus',
  om: {}
};
export function getGlobal(_a) {
  var _b = _a.maxAttempts,
    maxAttempts = _b === void 0 ? 1 : _b,
    key = _a.key,
    defaultReturn = _a.defaultReturn;
  return __awaiter(this, void 0, void 0, function () {
    var attempt;
    return __generator(this, function (_c) {
      switch (_c.label) {
        case 0:
          attempt = 0;
          _c.label = 1;
        case 1:
          if (!(!window.hasOwnProperty(key) && attempt < maxAttempts)) return [3, 3];
          return [4, new Promise(function (resolve) {
            return setTimeout(resolve, 100);
          })];
        case 2:
          _c.sent();
          attempt++;
          return [3, 1];
        case 3:
          return [2, Promise.resolve(deepGet(window, key, defaultReturn))];
      }
    });
  });
}
export function createAdobeTrackingSuiteInstance(analyticsConfig) {
  var s_gi = window.s_gi;
  var reportingSuiteId = analyticsConfig.reportingSuiteId,
    trackingServer = analyticsConfig.trackingServer,
    trackingServerSecure = analyticsConfig.trackingServerSecure;
  var s = s_gi(reportingSuiteId);
  omniturePlugins.loadPlugins(s);
  s.linkInternalFilters = 'javascript:,espn.go.com,espn.com,jayski.com,cricinfo.com,scrum.com,nasn.com,espnclassicsport.com,espnshop.com,espn360.com,horseracing.com,expn.go.com,expn.com,espntv.com,myespn.go.com,starwave.com,x.go.com,soccernet.com,soccernet.fr,soccernet.es,soccernet.it,soccernet.de,espndeportes.com,espndeportes.fr,espndeportes.es,espndeportes.it,espndeportes.de,spanishflytv.com,redfishcup.com,espnclassic.com,racing-live.com,quiznosmadfin.com,collegebass.com,espnamerica.com,espnstar.com,espndb.go.com,espn.co.uk,shop.expn.com,grantland.com,espnpub01,espnmast01,vwtsbar02,b.espncdn.com,espncdn.com,a.espncdn.com,fantasybeta.espn.go.com,espn-ffl-2013-stage.sportsr.us,espn-ffl-2013.sportsr.us,espnsync.com,espnfivethirtyeight.wordpress.com,fivethirtyeight.com,projects.fivethirtyeight.com,espn.com.mx,espn.com.ar,espn.com.ve,espn.com.co,espnfc.com,espnfc.us,espnfc.co.uk,espnfc.mx,espnfc.com.ar,espnfc.com.ve,espnfc.com.co,espnfc.com.br,espnfc.com.ng,espnfc.com.sg,espnfc.com.au,espnfc.com.my,espnfc.co.id,secsports.go.com,secsports.com,secsports.com,espn.uol.com.br,espnplayer.com,footytips.com.au,espn.com.au,espnview.com.au,espn.cl,espnfcasia.com,sonyespn.com,espn.in,kwese.espn.com,espn-development.com';
  s.trackingServer = trackingServer;
  s.trackingServerSecure = trackingServerSecure;
  return s;
}
;
export function getVisitorInstance(analyticsConfig) {
  var Visitor = window.Visitor;
  var marketingCloudId = analyticsConfig.marketingCloudId,
    trackingServer = analyticsConfig.trackingServer,
    trackingServerSecure = analyticsConfig.trackingServerSecure;
  var visitorInstance = Visitor.getInstance(marketingCloudId, {
    trackingServer: trackingServer,
    trackingServerSecure: trackingServerSecure
  });
  return visitorInstance;
}
;
export function parseProductArrayToString(productArray) {
  if (Array.isArray(productArray) && productArray.length) {
    return productArray.join(',;').replace(/^/, 'D2C;');
  }
  return '';
}
export function buildClickTrackingObject(_a) {
  var trackingProps = _a.trackingProps,
    trackingObj = _a.trackingObj,
    externalContextData = _a.externalContextData;
  var linkTrackVars = ['products'];
  trackingObj.clearVars();
  var ctaButtonURL = trackingProps.ctaButtonURL,
    ctaButtonCopy = trackingProps.ctaButtonCopy,
    contextData = trackingProps.contextData,
    products = trackingProps.products,
    module = trackingProps.module;
  module = module ? " : " + module + " Click" : '';
  var linkName = "" + dssDataLayer.pageName + module;
  var asyncContextData = __assign({
    ctaButtonCopy: ctaButtonCopy,
    ctaButtonURL: ctaButtonURL,
    linkid: linkName,
    action: linkName
  }, contextData);
  if (products) {
    trackingObj.products = parseProductArrayToString(products);
  }
  trackingObj.contextData = __assign(__assign({}, externalContextData), asyncContextData);
  for (var prop in trackingObj.contextData) {
    if (trackingObj.contextData[prop] !== null) {
      linkTrackVars.push("contextData." + prop);
    }
  }
  trackingObj.linkTrackVars = linkTrackVars.join();
  return trackingObj;
}
;
export function trackAsync(defaultPropsToRemove) {
  return __awaiter(this, void 0, void 0, function () {
    var _a, adobeAnalytics, disneyAdobeAnalytics, espnTrackingEnabled, disneyTrackingEnabled, s, disneyPixel, buttonData, trackingProps, isDropdown, globalContextData, disneyGlobalContextData, trackDSS, trackDisney, e_1;
    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          return [4, getGlobal({
            maxAttempts: 15,
            key: 'espnTrackingConfig',
            defaultReturn: {}
          })];
        case 1:
          _a = _b.sent(), adobeAnalytics = _a.adobeAnalytics, disneyAdobeAnalytics = _a.disneyAdobeAnalytics, espnTrackingEnabled = _a.espnTrackingEnabled, disneyTrackingEnabled = _a.disneyTrackingEnabled;
          s = createAdobeTrackingSuiteInstance(adobeAnalytics);
          disneyPixel = createAdobeTrackingSuiteInstance(disneyAdobeAnalytics);
          buttonData = this.dataset;
          trackingProps = JSON.parse(decodeURIComponent(buttonData.tracking || '{}'));
          trackingProps.ctaButtonURL = buttonData.trackingScript || buttonData.loginSuccessUrl || this.href || '';
          trackingProps.module = buttonData.trackingModuleType || '';
          isDropdown = this.tagName === 'SELECT';
          trackingProps.ctaButtonCopy = isDropdown ? this.value : this.innerText.trim();
          return [4, getGlobalContextVars(s, defaultPropsToRemove)];
        case 2:
          globalContextData = _b.sent();
          return [4, getGlobalContextVars(disneyPixel, defaultPropsToRemove)];
        case 3:
          disneyGlobalContextData = _b.sent();
          _b.label = 4;
        case 4:
          _b.trys.push([4, 9,, 10]);
          if (!espnTrackingEnabled) return [3, 6];
          trackDSS = buildClickTrackingObject({
            trackingProps: trackingProps,
            externalContextData: globalContextData,
            trackingObj: s
          });
          return [4, trackDSS.tl(true, 'o', trackDSS.contextData.linkid, null)];
        case 5:
          _b.sent();
          _b.label = 6;
        case 6:
          if (!disneyTrackingEnabled) return [3, 8];
          trackDisney = buildClickTrackingObject({
            trackingProps: trackingProps,
            externalContextData: disneyGlobalContextData,
            trackingObj: disneyPixel
          });
          return [4, trackDisney.tl(true, 'o', trackDisney.contextData.linkid, null)];
        case 7:
          _b.sent();
          _b.label = 8;
        case 8:
          return [3, 10];
        case 9:
          e_1 = _b.sent();
          console.error(e_1);
          return [3, 10];
        case 10:
          return [2];
      }
    });
  });
}
;
export function buildPageTrackingObject(_a) {
  var trackingObj = _a.trackingObj,
    analyticsConfig = _a.analyticsConfig,
    sectionAdobeAnalyticsContextData = _a.sectionAdobeAnalyticsContextData,
    pageAdobeAnalyticsContextData = _a.pageAdobeAnalyticsContextData,
    adobeAnalyticsContextData = _a.adobeAnalyticsContextData,
    externalContextData = _a.externalContextData;
  var dataLayer = window.btmTracking.dssDataLayer;
  trackingObj.pageName = dataLayer.pageName;
  trackingObj.products = dataLayer.products;
  trackingObj.visitor = getVisitorInstance(analyticsConfig);
  trackingObj.contextData = __assign(__assign(__assign(__assign(__assign({}, trackingObj.contextData), sectionAdobeAnalyticsContextData && __assign({}, sectionAdobeAnalyticsContextData)), pageAdobeAnalyticsContextData && __assign({}, pageAdobeAnalyticsContextData)), adobeAnalyticsContextData && __assign({}, adobeAnalyticsContextData)), externalContextData);
  return trackingObj;
}
export function trackPageView() {
  return __awaiter(this, void 0, void 0, function () {
    var analyticsConfig, adobeAnalytics, disneyAdobeAnalytics, espnTrackingEnabled, disneyTrackingEnabled, sectionAdobeAnalyticsContextData, pageAdobeAnalyticsContextData, adobeAnalyticsContextData, contextData, s, disneyPixel, globalContextData, disneyGlobalContextData, trackDSS, trackDisney;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4, getGlobal({
            maxAttempts: 15,
            key: 'espnTrackingConfig',
            defaultReturn: {}
          })];
        case 1:
          analyticsConfig = _a.sent();
          adobeAnalytics = analyticsConfig.adobeAnalytics, disneyAdobeAnalytics = analyticsConfig.disneyAdobeAnalytics, espnTrackingEnabled = analyticsConfig.espnTrackingEnabled, disneyTrackingEnabled = analyticsConfig.disneyTrackingEnabled, sectionAdobeAnalyticsContextData = analyticsConfig.sectionAdobeAnalyticsContextData, pageAdobeAnalyticsContextData = analyticsConfig.pageAdobeAnalyticsContextData, adobeAnalyticsContextData = analyticsConfig.adobeAnalyticsContextData, contextData = analyticsConfig.delayedValues.contextData;
          s = createAdobeTrackingSuiteInstance(adobeAnalytics);
          disneyPixel = createAdobeTrackingSuiteInstance(disneyAdobeAnalytics);
          return [4, getGlobalContextVars(s)];
        case 2:
          globalContextData = _a.sent();
          return [4, getGlobalContextVars(disneyPixel)];
        case 3:
          disneyGlobalContextData = _a.sent();
          dssDataLayer = __assign(__assign(__assign({}, dssDataLayer), globalContextData), contextData);
          if (!espnTrackingEnabled) return [3, 5];
          trackDSS = buildPageTrackingObject({
            externalContextData: globalContextData,
            trackingObj: s,
            analyticsConfig: analyticsConfig.adobeAnalytics,
            sectionAdobeAnalyticsContextData: sectionAdobeAnalyticsContextData,
            pageAdobeAnalyticsContextData: pageAdobeAnalyticsContextData,
            adobeAnalyticsContextData: adobeAnalyticsContextData
          });
          return [4, trackDSS.t()];
        case 4:
          _a.sent();
          _a.label = 5;
        case 5:
          if (!disneyTrackingEnabled) return [3, 7];
          trackDisney = buildPageTrackingObject({
            externalContextData: disneyGlobalContextData,
            trackingObj: disneyPixel,
            analyticsConfig: analyticsConfig.disneyAdobeAnalytics,
            sectionAdobeAnalyticsContextData: sectionAdobeAnalyticsContextData,
            pageAdobeAnalyticsContextData: pageAdobeAnalyticsContextData,
            adobeAnalyticsContextData: adobeAnalyticsContextData
          });
          return [4, trackDisney.t()];
        case 6:
          _a.sent();
          _a.label = 7;
        case 7:
          window.dssDataLayer = dssDataLayer;
          return [2];
      }
    });
  });
}
;
window.btmTracking = {
  dssDataLayer: dssDataLayer,
  _getGlobalContextVars: getGlobalContextVars,
  trackAsync: trackAsync,
  trackPageView: trackPageView,
  userTrackingInfo: {}
};