var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
import uuid from "../tracking-minified/uuid";
import cookies from "btm.cookies/cookies";
import { deepGet } from "bam-deep-get";
export function getPaywallVisitCount() {
  var count = cookies.get("paywallvisitcount");
  count = count ? Number(count) : 0;
  count++;
  cookies.set("paywallvisitcount", count);
  return count;
}
export function getVisitorData(s) {
  var lastvisit = unescape(s.getDaysSinceLastVisit("s_c24"));
  var visitortype = "Repeat";
  if (lastvisit === "First Visit") {
    visitortype = "New";
  } else if (lastvisit === "Cookies Not Supported") {
    visitortype = lastvisit;
  }
  return {
    lastvisit: lastvisit,
    visitortype: visitortype
  };
}
export function normalizeMapParams(params) {
  params.forEach(function (v, k, m) {
    if (k.indexOf("om-") === 0) {
      m.set(k.substr(3), v);
    }
  });
  return params;
}
export function delayedValueOverrides(maxAttempts) {
  if (maxAttempts === void 0) {
    maxAttempts = 1;
  }
  return __awaiter(this, void 0, void 0, function () {
    var attempt;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          attempt = 0;
          _a.label = 1;
        case 1:
          if (!(!window.hasOwnProperty("espnTrackingConfig") && attempt < maxAttempts)) return [3, 3];
          return [4, new Promise(function (resolve) {
            return setTimeout(resolve, 100);
          })];
        case 2:
          _a.sent();
          attempt++;
          return [3, 1];
        case 3:
          return [2, Promise.resolve(deepGet(window, "espnTrackingConfig.delayedValues.contextData", {}))];
      }
    });
  });
}
export default function (s, propsToExclude) {
  return __awaiter(this, void 0, void 0, function () {
    var user, queryMap, delayedValues, contextData, optimizelyVar;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          user = window.btmTracking.userTrackingInfo;
          queryMap = normalizeMapParams(new URLSearchParams(window.location.search));
          return [4, delayedValueOverrides(50)];
        case 1:
          delayedValues = _a.sent();
          contextData = __assign(__assign(__assign({
            paywallvisitcount: getPaywallVisitCount(),
            paywallshown: "yes",
            site: "espnplus",
            swid: deepGet(user, "oneIdGuestInformation.swid", uuid()),
            contenttype: "paywall",
            section: "espnplus",
            loginstatus: user.accountDetails.isLoggedIn || "anonymous",
            premium: "premium-no",
            registrationtype: "unknown",
            lang: "en_us",
            sport: "no sport",
            league: "no league",
            edition: "en-us",
            countryregion: "en-us",
            webAppName: "espnplus",
            prevpage: 'espnplus',
            internalmkt: queryMap.get("addata"),
            externalmkt: queryMap.get("ex_cid"),
            unid: user.unid || 'no entitlements',
            navmethod: queryMap.get("navmethod"),
            subscribertype: deepGet(user, "accountDetails.subscriptionType", "no entitlements"),
            purchasemethod: deepGet(user, "accountDetails.provider", "not applicable"),
            entitlements: user.entitlements.length > 0 ? user.entitlements : "no entitlements",
            DSSID: user.dssId || "not applicable",
            linkid: 'https://plus.espn.com',
            urlSlug: window.location.pathname
          }, getVisitorData(s)), window.btmExtraTrackingProps && __assign({}, window.btmExtraTrackingProps)), delayedValues && __assign({}, delayedValues));
          optimizelyVar = window.btmOptimizelyVar;
          if (optimizelyVar) {
            contextData.optimizelyvar = optimizelyVar;
          }
          if (propsToExclude && propsToExclude.length) {
            propsToExclude.forEach(function (prop) {
              delete contextData[prop];
            });
          }
          return [2, contextData];
      }
    });
  });
}