export var BroadcastServiceActions = {
  Application: {
    initialized: "__applicationInitialized__",
    embedReady: "__applicationEmbedReady__",
    ready: "__applicationReady__"
  },
  SyndicatedPlayer: {
    ready: "__syndicatedPlayerConfigReady__"
  },
  User: {
    userSet: "__user_set__",
    trackingInfo: "__trackingInfo__",
    loggedIn: "__user_loggedIn__"
  },
  SdkService: {
    sdkServiceReady: "__sdkServiceReady__",
    sdkSessionResetOnError: "__sdkSessionResetOnError__"
  },
  Paywall: {
    renderPaywall: "__render_paywall__"
  }
};